import barramento from '@/barramento'
var selectOptionsStorage = {
    data() {
        return {
            items: [],
            item: []
        }
    },
    watch: {
        items() {
            barramento.$emit('tagChanged')
        }
    },
    methods: {
        addTag: function (key,value) {
            var checkItem = this.getTagsFromKey(key);
            if (checkItem != false) {
                this.item[key] = checkItem
            }
            if (this.item[key] == undefined) {
                this.item[key] = new Array()
                this.item[key].push(value)
            } else {
                this.item[key].push(value) 
            }
            sessionStorage.removeItem(key)
            sessionStorage.setItem(key, JSON.stringify(this.item[key]))
            this.item[key] = []            
        },
        removeTag: function (key,value) {
            var newItem = this.getTagsFromKey(key);
            this.item[key] = new Array()
            if (newItem != false) {
                if (newItem != undefined) {
                    newItem.forEach(function (item) {
                        if (value.id != item.id) {
                            this.item[key].push(item)  
                        }
                    }.bind(this))
                }
                sessionStorage.removeItem(key)
                sessionStorage.setItem(key, JSON.stringify(this.item[key]))
            }
        },
        removeAllTags: function() {
            sessionStorage.clear();
        },
        checkIfTagExists: function(key,value) {
            if (sessionStorage[key] != undefined) {
                if (sessionStorage[key].indexOf(value) <= -1) {
                    return false     
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        getTagsFromKey: function(key) {
            if (sessionStorage[key] != undefined) {
                var sessionArray = JSON.parse(sessionStorage[key])
                if (sessionArray.length > 0) {
                    return sessionArray
                }
            }
            return false
        },
        getDataWithoutExtraFilters: function(key) {
            if (sessionStorage[key] != undefined) {
                var sessionArray = JSON.parse(sessionStorage[key])
                if (sessionStorage[key].indexOf('extraFilters') > -1) {                    
                    return sessionArray.filter(el => el.extraFilters == undefined)
                } else {                    
                    return sessionArray
                }
            }            
            return false     
        },
        getExtraFilters: function(key,extraFilterKey) {            
            var filterValue = false
            if (sessionStorage[key] != undefined) {
                if (sessionStorage[key].indexOf('extraFilters') > -1) {
                    var sessionArray = JSON.parse(sessionStorage[key])
                    sessionArray.forEach(function(item) {
                        if (item.extraFilters != undefined) {
                            filterValue = item.extraFilters[extraFilterKey]
                        }
                    })
                }
            }            
            return filterValue
        },
        removeAlTagsFromKey: function(key) {
            sessionStorage.removeItem(key)
        },
    }
  }

  export default selectOptionsStorage