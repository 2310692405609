<template>
        <div class="text-center carregando">
            CARREGANDO
            <b-spinner class="spinner" variant="success" label="Text Centered"></b-spinner>
        </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" >
.carregando{
    height: 50px;
}
</style>