<template>
    <div class="d-flex align-items-center justify-content-center flex-wrap" v-if="!isMobile">
        <form class="form form-inline text-nowrap">
          <input id="input-busca" class="search_bar" type="text" :value="value" placeholder="Buscar o nome científico ou popular da espécie" ref="inputBusca" @paste="onPaste" @keydown.enter="(e) => {searchAction(false,e)}">
          <button v-if="!advanced" class="lupa-button"  @click="(e) => {searchAction(false,e)}">Buscar <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt=""></button>
          <i class="fa fa-info lupa-button-info" title="Para várias espécies, copie e cole a partir de uma planilha ou digite separado por &quot;,&quot; ou &quot;;&quot;
Exemplo: Puma concolor, Aburria cujubi"></i>
        </form>
    </div>
    <div class="col-12" v-else>
        <form class="form form-inline text-nowrap">
            <input
                class="search_bar" type="text" :value="value" @paste="onPaste" @input="getValue" placeholder="Buscar o nome científico ou popular da espécie" ref="inputBusca" @keydown.enter="(e) => {searchAction(false,e)}">
            <button v-if="!advanced" class="lupa-button" @click="(e) => {searchAction(false,e)}">Buscar <img :src="image" @mouseover="imageHover" @mouseout="imageOut" alt=""></button>
            <i class="fa fa-info lupa-button-info" title="Para várias espécies, copie e cole a partir de uma planilha ou digite separado por &quot;,&quot; ou &quot;;&quot;
Exemplo: Puma concolor, Aburria cujubi"></i>
        </form>
    </div>
</template>

<script>
import barramento from '@/barramento'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
import { isMobile } from 'mobile-device-detect';
export default {
    props: ['advanced'],
    data() {
        return {
            value: "",
            image: '/img/lupa2.png',
            lupaImageHover: '/img/lupa2.png',
            storageKey: 'q',
            isMobile: isMobile,
        }
    },
    mixins:[selectOptionsStorage],
    created(){
        if (this.getTagsFromKey(this.storageKey) != false) {
            this.value=this.getTagsFromKey(this.storageKey)
        }
        barramento.$on('openModalR', () => {
            if (this.getTagsFromKey(this.storageKey) != false) {
                this.value=this.getTagsFromKey(this.storageKey)
            }
        })
        barramento.$on("buttonLimparFiltrosClear", ()=>{
            this.value=""
        })
        barramento.$on('searchClick', (isAdvanced) => {
            this.searchAction(isAdvanced)
        })
    },
    methods: {
      onPaste(evt) {
        try {
          evt.stopPropagation();
          evt.preventDefault();
          var cd = evt.originalEvent.clipboardData;
          var text = cd.getData('text/plain');
          var lista = text.trim().replace(/; {1,}?;/gm, ';').replace(/"/gm, '').replace(/\t|\n|,/gm, ';').split(';')
          var value = evt.target.value;
          value = String((value == '' ? '' : value + '; ') + lista.join('; ')).replace(/[;,]{2,}/g, ';');
          evt.target.value = value;
        } catch (error) {
          try {
            navigator.clipboard.readText().then(function (text) {
              var lista = text.trim().replace(/; {1,}?;/gm, ';').replace(/"/gm, '').replace(/\t|\n|,/gm, ';').split(';')
              var value = evt.target.value;
              value = String((value == '' ? '' : value + '; ') + lista.join('; ')).replace(/[;,]{2,}/g, ';');
              evt.target.value = value;
            }, function (err) {
              return err;
            });
          } catch( e2 ){
               alert('O acesso a área de transferência não está habilitado neste navegador.\n\nPara autorizar faça:\n'+
            '1) Em uma nova aba digite: about:config na barra de endereço e pressione Enter;\n'+
            '2) Clique no botão: "Aceitar o risco e continuar;"\n'+
            '3) Pesquise por: dom.events.asyncClipboard.readText e altere o valor para true;\n'+
            '4) Pesquise por: dom.events.testing.asyncClipboard e altere o valor para true.\n'+
            '5) Fechar a aba de configurações e se necessário pressione F5 para aplicar as alterações.'
            );

          }
        }
      },
      getValue(e) {
            if (e.currentTarget.value.length == 0) {
                this.removeAlTagsFromKey(this.storageKey)
            }
        },
        searchAction(isAdvanced=false,e=undefined){
            if (this.$refs.inputBusca != undefined) {
                if (this.checkIfTagExists('filterFromGraphic','true') || isAdvanced == false) {
                    barramento.$emit("buttonLimparFiltrosClear")
                }
                var query = ''
                if(this.$refs.inputBusca){
                    query = this.$refs.inputBusca.value
                }
                this.removeAlTagsFromKey(this.storageKey)
                if (query.length > 0) {
                    this.addTag(this.storageKey,query)
                }
                barramento.$emit('openModalR')
                if (e != undefined) {
                    e.preventDefault()
                }
            }
        },
        imageHover(e) {
            e.target.src = this.lupaImageHover
        },
        imageOut(e) {
            e.target.src = this.image
        },
    }
}

</script>

<style lang="scss" scoped>
    .fa-info {
      background: #e2f5a1;
      border-radius: 20px;
      padding: 4px 10px;
      cursor:pointer;
    }
    @media only screen and (min-width: 769px) {
         .search_bar {
            width: 650px;
            height: 10px;
            margin: auto;
            margin-top: 23px;
            padding: 30px 30px 29px 44px;
            border-radius: 40px;
            border: none;
            box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            font-size: 1.0rem;
        }
        .lupa-button {
            background: #E2F5A1 !important;
            border-radius: 20px;
            padding: 5px 10px 5px 20px;
            border: none;
            margin-left: -135px;
            font-size: 12px;
            margin-top:30px;
        }
        .lupa-button img {
            margin-left: 5px;
            width: 20px;
        }
        .lupa-button-info {
          font-size: 1.0rem;
          margin-top:30px;
        }
    }
    @media only screen and (max-width: 768px) {
        .search_bar::-webkit-input-placeholder {
            white-space:pre-line;
            position:relative;
            top:-7px;
            width:55%;
        }
        /* .search-bar-mobile::placeholder {
            font-size: .65rem;
            width:60%;
            word-wrap:break-word;
            border:1px solid red;
        } */
        .search_bar {
            width: 100%;
            height: 10px;
            margin: auto;
            margin-top: 23px;
            padding: 30px 10px 30px 20px;
            border-radius: 40px;
            border: none;
            box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            font-size: .7rem;
        }
        .lupa-button {
            background: #E2F5A1 !important;
            border-radius: 20px;
            padding: 5px 10px 5px 20px;
            border: none;
            font-size: 0.6rem;
            margin-left: -120px;
            margin-top:30px;
            opacity: 0.7;
        }
        .lupa-button img {
            margin-left: 5px;
            width: 20px;
        }
        .lupa-button-info {
          margin-top:30px;
          font-size: .7rem;
          /*font-size:10px;*/
        }
    }
</style>
