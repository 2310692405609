var util = {
    methods: {
        formatNumber(value, addDecimal = false) {
            var valueString = value.toString()
            let newValue = "";
            if (valueString.indexOf('.') > -1) {
                newValue = valueString.replace('.', ',')
            } else {
                if (addDecimal) {
                    newValue = valueString + ',0'
                } else {
                    newValue = valueString
                }
            }

            return newValue
        },
        l(e) {
            console.log(e)
        }, isEmail(email) {
            if (String(email).trim() == '') {
                return true;
            } else {
                // eslint-disable-next-line
                let res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return res.test(email.toLowerCase());
            }
        }
    }
}
export default util
