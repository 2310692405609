import barramento from '@/barramento'
var toggleFilterStorage = {
    data() {
        return {
            items: ""
        }
    },
    methods: {
        toggleItem: function (key,value) {
            //activeFilterButton
            if (sessionStorage[key] == undefined) {
                this.items = value
            } else {
                this.items = sessionStorage[key];
                if (this.items.indexOf(value) == -1) {
                    this.items = this.items + ',' + value
                } else {
                    this.items = this.items.replace(value,'');
                }
                if (this.items.indexOf(',,') > -1) {
                    this.items = this.items.replace(',,',',');
                }
                if (this.items.indexOf(',') == 0) {
                    this.items = this.items.substring(1);
                }
                if (this.items.lastIndexOf(',') == this.items.length - 1) {
                    this.items = this.items.substring(0,this.items.length-1); 
                }
                sessionStorage.removeItem(key)
            }
            if (this.items.length > 0) {
                sessionStorage.setItem(key, this.items)
            }
            barramento.$emit('filterChanged')
        },
        removeAllItems: function() {
            sessionStorage.clear();
            barramento.$emit('toggleFilterStorageRemoveAll')
        },
        checkIfExists: function(key,value) {
            if (sessionStorage[key] != undefined) {
                if (sessionStorage[key].indexOf(value) <= -1) {
                    return false     
                } else {
                    return true
                }
            }
        },
        getItemsFromKey: function(key) {
            if (sessionStorage[key] != undefined) {
                return sessionStorage[key]
            } else {
                return false
            }
        }

    }
  }

  export default toggleFilterStorage