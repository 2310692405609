<template>
    <div class="legend float-right">
       <img src="/img/legendAnimalChart.svg" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .legend {
        margin-top: -40px;
        margin-right: 40px;
    }
    .ball {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        vertical-align: center;
    }
    .redBall{
        background-color: red;
    }
    .orangeBall{
        background-color: orange;
    }
    .yellowBall{
        background-color: yellow;
    }
@media screen and (max-width: 600px) {
    .legend {
        margin-top: 2rem;
        margin-right: 0px;
    }
}
</style>