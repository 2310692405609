<template>
 <div class="container-fluid">
  <div id="main" v-if="!mobile">
      <ComoCitar />
      <modal-info />
      <nav class="main-nav d-flex align-items-center justify-content-center">
          <Burger burger></Burger>
          <a href="#/sobre-o-sistema-salve"><img class="logo" src="/img/logo-salve.svg" alt="logo" title="Sistema de Avaliação do Risco de Extinção da Biodiversidade – SALVE"  height="48px"></a>
          <a href="https://www.gov.br/icmbio/pt-br" target="_blank"><img class="logo2" src="/img/logo-icmbio.gif" alt="logo" title="Instituto Chico Mendes de Conservação da Biodiversidade - ICMBio"  height="59px"></a>
      </nav>
      <Sidebar>
        <ul class="sidebar-panel-nav">
          <li class="text-left"><router-link to="/" @click.native="closeModal">Página inicial</router-link></li>
          <!--<li class="text-left"><router-link to="/apresentacao-em-video" @click.native="closeModal">Apresentação em Vídeo</router-link></li>-->
          <li class="text-left"><router-link to="/sobre-o-sistema-salve" @click.native="closeModal">Sobre o SALVE</router-link></li>
          <li class="text-left"><router-link to="/processo-de-avaliacao" @click.native="closeModal">Processo de Avaliação</router-link></li>
          <li class="text-left"><a href="/salve-consulta/" target="_blank" @click.native="closeModal">Consultas Abertas</a></li>
          <li class="text-left"><router-link to="/metodos-de-avaliacao" @click.native="closeModal">Método de Avaliação</router-link></li>
          <li class="text-left"><router-link to="/livro-vermelho" @click.native="closeModal">Publicações</router-link></li>
          <li class="text-left"><router-link to="/quem-somos" @click.native="closeModal">Quem Somos</router-link></li>
          <li class="text-left"><a @click="comoCitar" class="hand">Como Citar</a></li>
          <li class="text-left"><router-link to="/creditos" @click.native="closeModal">Créditos das Fotos</router-link></li>
          <li class="text-left"><router-link to="/fale-conosco" @click.native="closeModal">Fale Conosco</router-link></li>

        </ul>
      </Sidebar>

<!--    <h1 style="margin-top:180px;z-index: 10">Sistema em manutenção.<br><small style="font-size:1.5rem">Retorno previsto para às 20:00 hs</small></h1>-->


      <vue-page-transition name="zoom">
        <router-view />
      </vue-page-transition>
      <Footer />
      <VLibras />
      <Translate />
    </div>
    <div id="main" v-else>
      <ComoCitarMB />
      <nav class="main-nav d-flex align-items-center justify-content-center">
          <BurgerMB burger></BurgerMB>
          <a href="/"><img class="logo" src="/img/logo-salve.svg" alt="logo" title="Sistema de Avaliação do Risco de Extinção da Biodiversidade – SALVE"  height="48px"></a>
          <a href="https://www.gov.br/icmbio/pt-br" target="_blank"><img class="logo2" src="/img/logo-icmbio.gif" alt="logo" title="Instituto Chico Mendes de Conservação da Biodiversidade - ICMBio"  height="59px"></a>
      </nav>

      <SidebarMB>
        <ul class="sidebar-panel-nav">
          <li class="text-left" ><router-link to="/" @click.native="closeModal">Home</router-link></li>
          <!--<li class="text-left"><router-link to="/apresentacao-em-video" @click.native="closeModal">Apresentação em Vídeo</router-link></li>-->
          <li class="text-left"><router-link to="/sobre-o-sistema-salve" @click.native="closeModal">Sobre o SALVE</router-link></li>
          <li class="text-left"><router-link to="/processo-de-avaliacao" @click.native="closeModal">Processo de Avaliação</router-link></li>
          <li class="text-left"><a href="/salve-consulta/" target="_blank" @click.native="closeModal">Consultas Abertas</a></li>
          <li class="text-left"><router-link to="/metodos-de-avaliacao" @click.native="closeModal">Método de Avaliação</router-link></li>
          <li class="text-left"><router-link to="/livro-vermelho" @click.native="closeModal">Publicações</router-link></li>
          <li class="text-left"><router-link to="/quem-somos" @click.native="closeModal">Quem Somos</router-link></li>

          <li class="text-left"><a @click="comoCitar">Como Citar</a></li>
          <li class="text-left"><router-link to="/creditos" @click.native="closeModal">Créditos das Fotos</router-link></li>
          <li class="text-left"><router-link to="/fale-conosco" @click.native="closeModal">Fale Conosco</router-link></li>

        </ul>
      </SidebarMB>


<!--      <h1 style="margin-top:180px;z-index: 10">Sistema em manutenção.<br><small style="font-size:1.5rem">Retorno previsto para às 20:00 hs</small></h1>-->


      <vue-page-transition name="zoom">
        <router-view />
      </vue-page-transition>
      <FooterMB />
      <VLibras />
      <Translate />
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

import Burger from '@/web/components/templates/Burger.vue';
import Sidebar from '@/web/components/templates/Sidebar.vue';
import Footer from '@/web/components/templates/Footer.vue';
import ComoCitar from '@/web/modais/ComoCitar.vue'

import BurgerMB from '@/mobile/components/templates/Burger.vue';
import SidebarMB from '@/mobile/components/templates/Sidebar.vue';
import FooterMB from '@/mobile/components/templates/Footer.vue';
import ComoCitarMB from '@/mobile/modais/ComoCitar.vue';
import barramento from '@/barramento';
import { VLibras } from '@vue-a11y/vlibras';
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'
import Translate from './components/globalComponents/Translate.vue';
import ModalInfo from './web/modais/ModalInfo.vue';


export default {
 name: 'app',
 components: {
    Burger,
    Sidebar,
    Footer,
    VLibras,
    ComoCitar,
    BurgerMB,
    SidebarMB,
    FooterMB,
    ComoCitarMB,
    Translate,
    ModalInfo
},
 mixins:[toggleFilterStorage],
 data() {
   return {
     mobile: isMobile,
   }
 },
 created() {
   // inicializar google reCaptcha

   // EUGENIO
   //window.reCaptchaPublicKey='6LcaM_MnAAAAAIejNgewG2oVhTwbqjJn3azoPiS6';

   // ICMBIO
   window.reCaptchaPublicKey='6Ley4O8nAAAAAKY5Rmg53NE-6cqRovFQ5ggybiFy';


   window.grecaptcha.ready(async () => {});

   // monitorar o scroll da página
   window.addEventListener('scroll', this.handleScroll);
   barramento.$on('buttonLimparFiltrosClear', ()=>{this.removeAllItems()})
 },
 destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
 },
 methods: {
    handleScroll () {
      // Any code to be executed when the window is scrolled
      var mainNav = document.getElementsByClassName('main-nav');
      if (window.scrollY == 0) {
        if (isMobile) {
          mainNav[0].style.top = '108px';
        } else {
          mainNav[0].style.top = '159px';
        }
      } else {
        if (isMobile) {
          mainNav[0].style.top = '56px';
        } else {
          mainNav[0].style.top = '88px';
        }
      }
    },
   closeModal() {
     barramento.$emit('closeSide')
     window.scrollTo(0, 0);
   },
   comoCitar(){
     barramento.$emit('closeSide')
     barramento.$emit('modal_como_citar')
   },
 }
}
</script>
<style>

  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  .hand{
    cursor: pointer;
  }
  .card {
    border:none !important;
  }
  [burger] {
    position: absolute;
    left: 0%;
    padding: 15px;
    margin-left: 10px;
    z-index: 10000;
  }
  *:active, *:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .logo2 {
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 10px;
    margin-right: 22px;
    z-index: 10000;
  }

  #main {
    font-family: Roboto, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    outline: none;

  }
  .bar {
      background-color:#ffffff;
      position: fixed;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 60px;
      z-index: 1000;
      /* box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15); */
  }

  .logo {
      margin-top: -5px;

  }
 .main-nav {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.8rem;
    background-color:#ffffff;
    position: fixed;
    top: 159px;
    left: 0%;
    width: 100%;
    height: 80px;
    z-index: 10;
 }

   @media only screen and (max-width: 575px) {
         .main-nav {
            top: 108px;
        }

        .grecaptcha-badge {
         display: none !important;
      }
    }

 ul.sidebar-panel-nav {
   list-style-type: none;
   margin-left: -40px;
   margin-top: 20px;
 }

 ul.sidebar-panel-nav > li > a {
   color: #282828;
   text-decoration: none;
   font-family: Roboto;
   font-size: 18;
   font-weight: bold;
   display: block;
   padding-bottom: 20px;
   padding-top: 20px;
   border-bottom: 0.5px solid #eee;
 }

 ul.sidebar-panel-nav > li:hover {
   background-color: #e2f5a1;
   padding-left: 2px;
   padding-right: 3px;
   margin-left: 2px;
   margin-right: 3px;

 }
</style>
