import Vue from 'vue'
import VueScrollmagic from 'vue-scrollmagic'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCarousel from 'vue-carousel'
import LottieAnimation from "lottie-vuejs"
import router from './router'
import VuePageTransition from 'vue-page-transition'
import api from './services/api'
import VueNotification from "@kugatsu/vuenotification";
import VoerroTagsInput from '@voerro/vue-tagsinput'
import Multiselect from 'vue-multiselect'
import VueMdb, { AxiosPlugin } from "vue-mdbootstrap";
import LoadingAjax from '@/components/globalComponents/Loading'
import util_mixin from './util_mixins.js'

// Default requirement
Vue.use(VueMdb);


// Global mixins
Vue.mixin(util_mixin);

// Optionally, install the MDBootstrap Axios plugin
// only requires if using BsGrid, BsTreeGrid, BsModel, BsStore, BsTreeStore or needs to perform HTTP Request
Vue.use(AxiosPlugin);


Vue.config.productionTip = false;

Vue.use(VueScrollmagic)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCarousel)
Vue.use(LottieAnimation)
Vue.use(VuePageTransition)
Vue.use(VueNotification, {
  timer: 20,
  error: {
    background: '#FFE2DE',
    color: "black"
  },
  primary: {
    background: "#F7FFDE",
    color: "black"
  }
});
Vue.component('tags-input', VoerroTagsInput);
Vue.component('multiselect', Multiselect)
Vue.component('loading-ajax', LoadingAjax);

Vue.prototype.$http = api;

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import lazyLoadComponent  from '@/lazy-load-component.js'

Vue.config.productionTip = false

const HeaderGovFederal = () => import(/* webpackChunkName: "header-gov-federal" */ './components/govFederalTemplate/HeaderGovFederal')
new Vue({
  components: {HeaderGovFederal},
  render: h => h(HeaderGovFederal)
}).$mount('#header-gov-br')

const FooterGovFederal =
    lazyLoadComponent({
      componentFactory: ()  => import(/* webpackChunkName: "footer-gov-federal" */ './components/govFederalTemplate/FooterGovFederal'),
      loading: "loading-ajax",
    })
new Vue({
  components: {FooterGovFederal},
  render: h => h(FooterGovFederal)
}).$mount('#footer-gov-br')

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
api.defaults.timeout = 100000;
api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
error => {
    console.log("Ocorreu um erro:")
    console.log(error)
    if (error.response != undefined && error.response.status) {
      switch (error.response.status) {
        case 400:
            alert("Ops! Tentativa de solicitação inválida. Erro: 400");
            break;
        case 401:
            alert("Ops! Você não está autorizado. Erro: 401");
            break;
        case 404:
            alert('Ops! Ocorreu um erro. Recurso da solicitação não encontrado. Favor tentar mais tarde. Erro: 404');
            break;
        case 500:
            alert('Ops! Ocorreu um erro no servidor. Favor contactar o administrador. Erro: 500');
            break;
        default:
            alert('Ops! Ocorreu um erro não esperado. Favor tentar mais tarde.');
            break;
      }
      return Promise.reject(error.response);
    }
  }
);
/*
api.interceptors.request.use(config => {
  console.log('INTERCEPT REQUEST')
  if( /search|faleConosco|totalNumbers/.test( config.url) ) {
    return new Promise(function (resolve, reject) {
      window.grecaptcha.ready( async function () {
        await window.grecaptcha.execute('6LcaM_MnAAAAAIejNgewG2oVhTwbqjJn3azoPiS6', {action: 'search'})
            .then( function ( token ) {
              if( !config.params) {
                //console.log('config params',config.params);
                config.params = {};
              }
              config.params.reCaptchaToken=token;
              resolve(config);
            }, function(err) {
              reject(err);
            });
      })
    });
  }
  return config;
});
*/
