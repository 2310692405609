<template>
  <div class="totalNumbers">
        <div v-if="animation">
            <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
        </div>
  </div>
</template>

<script>
import Lottie from './lottie.vue';
import dataBalls from './animations/dataBalls.json';
import dataBallsM from '@/mobile/components/animations/dataBalls-m.json';
import { isMobile } from 'mobile-device-detect';
// import barramento from '@/barramento'

export default {
    components: {
      'lottie': Lottie
    },
    props: ['data'],
    data() {
        return {
            animationJson: isMobile ? dataBallsM : dataBalls,
            animation: false,
            defaultOptions: Object,
            animController: Object,
            items: []
        }
    },
    mounted() {
        this.items=this.data
        this.setItems()     
    },
    watch: {
        defaultOptions(){
            this.animation = true
        }
    },
    methods: {
        setItems() {
            this.animationJson.assets[0].layers[0].t.d.k[0].s.t = this.separateString(this.items[0].label)
            this.animationJson.assets[0].layers[1].t.d.k[0].s.t = this.items[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

            this.animationJson.assets[1].layers[0].t.d.k[0].s.t = this.separateString(this.items[1].label)
            this.animationJson.assets[1].layers[1].t.d.k[0].s.t = this.items[1].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

            this.animationJson.assets[2].layers[0].t.d.k[0].s.t = this.separateString(this.items[2].label)
            
            /** (*) Asterisco - Remoção temporaria solicitada pela equipe em 07/jun/2022 devido a regras de lançamento do Salve Publico */
            this.animationJson.assets[2].layers[1].t.d.k[0].s.t = this.items[2].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '*'            
            

            this.animationJson.assets[3].layers[0].t.d.k[0].s.t = this.separateString(this.items[3].label)
            this.animationJson.assets[3].layers[1].t.d.k[0].s.t = this.items[3].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

            this.defaultOptions = {animationData: this.animationJson, loop: false}
        },
        handleAnimation(anim) {
            this.anim = anim;
            anim.playSegments([0, 50], true)
        },
        separateString(text) {
            var s = text.split(" ")
            var finalString = ''
            var temp = ''

            for (let k in s) {
                if(temp != ''){
                    if((s[k].length + temp.length) > 10){
                        finalString += temp + ' ' + s[k] + '\r'
                        temp = '';
                    } else if(s.length -1 == k) {
                        finalString += temp + ' ' + s[k]
                        temp = '';
                    } else {
                        temp += ' ' + s[k]
                    }
                } else {
                    if(s[k].length > 10){
                        finalString += s[k] + '\r'
                    } else if(s.length -1 == k) {
                        finalString += s[k]
                    } else {
                        temp = s[k] + ' '
                    }
                }
                
            }

            return finalString;
        },
        
    }
}
</script>

<style>
    .totalNumbers {
        margin: 15px;
        margin-top: 4rem;
    }
@media screen and (max-width: 600px) {
    .totalNumbers {
        margin: -2rem 0rem 0 -4rem;
    }
}
</style>