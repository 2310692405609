<template>
  <div class="footer">
    <a href="#/sobre-o-sistema-salve"><img src="/img/salve.svg" alt="Sistema de Avaliação do Risco de Extinção da Biodiversidade – SALVE" title="Sistema de Avaliação do Risco de Extinção da Biodiversidade – SALVE" class="mb-5 px-5 img-fluid"></a>
  </div>
</template>

<script>
export default {

}
</script>
<style scoped>
    .footer {
        margin-top: 60px;
        margin-bottom: 80px;
    }
</style>