<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen"
                 class="sidebar-panel">
                <button type="button" @click="closeSidebarPanel" class="button-slide" title="Menu de navegação do site">
                   <i class="text-danger fa fa-times fa-lg"></i>
                </button>
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
    import { store, mutations } from '@/store.js'
    import barramento from '@/barramento'

    export default {
        created() {
            barramento.$on('closeSide', ()=> {
                this.closeSidebarPanel()
            })
        },
        methods: {
            closeSidebarPanel: mutations.toggleNav
        },
        computed: {
            isPanelOpen() {
                return store.isNavOpen
            }
        }
    }
</script>
<style>
    .button-slide {
            position: relative;
            height: 30px;
            width: 32px;
            display: block;
            z-index: 100000;
            border: 0;
            border-radius: 0;
            background-color: transparent;
            pointer-events: all;
            transition: transform .6s cubic-bezier(.165,.84,.44,1);
        }
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }

    .sidebar-backdrop {
        /* background-color: rgba(0,0,0,.5); */
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    .sidebar-panel {
        overflow-y: auto;
        background-color: #F3FFC8;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 1002;
        padding: 3rem 20px 2rem 20px;
        width: 250px;
        z-index: 9999;

        box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        -webkit-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        -moz-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
    }

    button {
        cursor: pointer;
    }

    /* remove blue outline */
    button:focus {
        outline: 0;
    }
</style>