<template>

    <b-modal no-close-on-backdrop no-close-on-esc id="modal_como_citar" size="md"  :hide-header="true" hide-footer>
        <div class="close-button float-right"><button size="lg" @click="close()" title="Fechar janela">
              <i class="text-danger fa fa-times fa-lg"></i>
            </button>
        </div>
        <div class="card h-100 py-1">
            <h4><b>Como citar o site</b></h4>
            <div class="session-gap"></div>
            <div class="session-gap"></div>
            <div class="textCitar" v-html="text"></div>

        </div>
    </b-modal>
</template>

<script>
import barramento from '@/barramento'

export default {
    data() {
        return {
            text: ''
        }
    },
    async created() {
        var t = this
        barramento.$on('homeTotalNumbersApiLoaded',(data) => {
            t.text = data[4]["howToCite"]
        })
        barramento.$on('modal_como_citar', () => {
            this.$bvModal.show('modal_como_citar')
        })
    },
    methods: {
        close(){
             this.$bvModal.hide('modal_como_citar')
        }
    }
}
</script>

<style lang="scss">

    #modal_como_citar {
        .modal-dialog .modal-content {
            border-radius: 15px !important;
            padding: 30px !important;
            background-color: #E2F5A1;

            box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
            -webkit-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
            -moz-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        }
        .card {
            background-color: #E2F5A1;
        }
        .close-button button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
        }
        .textCitar {
            font-size: 1.1rem!important;
            color: #646464;
        }
        .session-gap {
            height: 15px;
        }

    }
</style>