<template>
        <div class="flag-translate">
          <a href="#" onclick="doGTranslate('pt|pt');return false;" title="ATENÇÂO! Tradução automática pelo Google tradutor" class="gflag nturl"><img src="/img/flag-BR.png" width="24"/></a>
          <a href="#" onclick="doGTranslate('pt|en');return false;" title="ATTENTION! Automatic translation by Google translator" class="gflag nturl"><img src="/img/flag-UK.png" width="24"/></a>
          <a href="#" onclick="doGTranslate('pt|es');return false;" title="ATENCIÓN! Traducción automática por traductor de Google" class="gflag nturl"><img src="/img/flag-ES.png" width="24"/></a>
          <div id="google_translate_element2"></div>
        </div>
</template>

<script>
export default {
}
</script>

<style>
.flag-translate{
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 70px;
    margin-right: 22px;
    z-index: 100;
 }

 a.gflag {vertical-align:middle;font-size:16px;padding:0px 2px;}
 #goog-gt-tt {display:none !important;}
 .goog-te-banner-frame {display:none !important;}
 .goog-te-menu-value:hover {text-decoration:none !important;}
 body {top:0 !important;}
 #google_translate_element2 {display:none!important;}

</style>