import axios from 'axios'

export default axios.create({
    //baseURL: process.env.VUE_APP_URL_API+'/public/',
    //baseURL: 'http://api-icmbio.herokuapp.com/salve-api/public/', // TESTE
    //baseURL: '/salve-api/public/', // FINAL CI/CD

    //baseURL: 'http://localhost:8090/salve-api/public/', // TESTE
    //baseURL: 'https://trnsalve.icmbio.gov.br/salve-api/public/', // TRN
    baseURL: 'https://salve.icmbio.gov.br/salve-api/public/', // FINAL

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // COM ACCESS-CONTROL O reCaptcha não funciona
        //'Access-Control-Allow-Origin': '*',
        //'Access-Control-Allow-Methods': '*',
    }
})

/*
newAxios.interceptors.request.use( config => {
    // Write whatever logic you want to run beforeSend here
    if( /search|faleConosco|totalNumbers/.test( config.url) ) {
        console.log('PEGAR TOKEN PARA URL '+config.url )
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LcaM_MnAAAAAIejNgewG2oVhTwbqjJn3azoPiS6', {action: 'search'}).then(function (token) {
                //config.params={reCaptchaToken:token}
                config.headers['Recaptcha-Token'] = token
            })
        });
    }
    console.log( config );
    return config; // very Important to add this, else Axios will cancel itself
});
*/

//export default newAxios;
