import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/web/views/Home.vue'
const VideoPresentation  = () => import(/* webpackChunkName: "video-presentation" */ './web/views/VideoPresentation.vue')
const RedBook = () => import(/* webpackChunkName: "red-book" */ './web/views/RedBook.vue')
const EvaluationProcess  = () => import(/* webpackChunkName: "evaluation-process" */ './web/views/EvaluationProcess.vue')
const AboutUs = () => import(/* webpackChunkName: "about-us" */ './web/views/AboutUs.vue')
const AboutSalve = () => import(/* webpackChunkName: "about-salve" */ './web/views/AboutSalve.vue')
const Credits = () => import(/* webpackChunkName: "credits" */ './web/views/Credits.vue')
const EvaluationMethods = () => import(/* webpackChunkName: "evaluation-methods" */ './web/views/EvaluationMethods.vue')
const SendEmail = () => import(/* webpackChunkName: "contact" */ './web/views/SendEmail.vue')

Vue.use(Router)

export default new Router({
    //mode: 'history',
    routes: [{
        path: '/',
        component: Home
    }, {
       path: '/apresentacao-em-video',
       component: VideoPresentation
    }, {
        path: '/livro-vermelho',
        component: RedBook
    }, {
        path: '/processo-de-avaliacao',
        component: EvaluationProcess
    }, {
        path: '/quem-somos',
        component: AboutUs
    }, {
        path: '/sobre-o-sistema-salve',
        component: AboutSalve
    }, {
        path: '/creditos',
        component: Credits
    }, {
        path: '/metodos-de-avaliacao',
        component: EvaluationMethods
    }, {
        path: '/fale-conosco',
        component: SendEmail
    }]
})